.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flying-word {
  position: fixed;
  background-color: #1d4ed8; /* Blue */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  z-index: 1000;
  animation: fly-to-arranged 0.6s ease forwards;
}

@keyframes fly-to-arranged {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(200px, 300px); /* Adjust these values dynamically in JavaScript */
    opacity: 0;
  }
}
.word-block {
  flex: 1 0 25%; /* Each word takes 1/4th of the width (25%) */
  margin: 0.5rem 0; /* Small margin between words */
  text-align: center; /* Center the text in the box */
}
body1{
  background: rgb(100,0,123);
  background: radial-gradient(circle, rgba(100,0,123,1) 0%, rgba(62,20,86,1) 100%);
  overflow: hidden;
}